.login-wrapper {
  padding: 20px;
  .form_container {
    margin: auto;
    margin-top: 10rem;
    max-width: 500px;
    padding: 2rem;
    border: 2px dashed #564ece;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: "";
      backdrop-filter: blur(8px);
    }
    .form_control {
      position: relative;
      margin: 20px 0 40px;
      width: 100%;
      z-index: 1;
      input {
        background-color: transparent;
        border: 0;
        border-bottom: 2px #fff solid;
        display: block;
        width: 100%;
        padding: 15px 0;
        font-size: 18px;
        color: black;
        &:focus,
        &:valid {
          outline: 0;
          border-bottom-color: #564ece;
        }
      }
      label {
        position: absolute;
        top: 15px;
        left: 0;
        pointer-events: none;
        span {
          display: inline-block;
          font-size: 18px;
          min-width: 5px;
          color: #564ece;
          -webkit-transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }
      }
    }

    .form_control input:focus + label span,
    .form_control input:valid + label span {
      color: #564ece;
      -webkit-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    .form_button {
      font-family: inherit;
      display: inline-block;
      width: 100%;
      height: 3em;
      line-height: 2.5em;
      margin: 20px 0;
      position: relative;
      overflow: hidden;
      border: 2px solid #564ece;
      -webkit-transition: color 0.5s;
      transition: color 0.5s;
      font-size: 17px;
      border-radius: 6px;
      font-weight: 500;
      z-index: 1;
      color: #564ece;
      background: white;
      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        background: #564ece;
        height: 150px;
        width: 100%;
        border-radius: 50%;
        top: 100%;
        left: 100%;
        -webkit-transition: all 0.7s;
        transition: all 0.7s;
      }
      &:hover {
        color: white;
      }
    }

    .form_button:hover:before {
      top: -25px;
      left: -15px;
    }

    .form_button:active:before {
      background: white;
      -webkit-transition: background 0s;
      transition: background 0s;
    }

    .forgot_password {
      position: relative;
      z-index: 1;
      cursor: pointer;
      font-size: 0.75rem;
    }
  }
}
