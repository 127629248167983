.uploadimage-wrapper {
  width: 100%;
  .main-content {
    padding: 30px;
    width: 100%;
    display: flex;
    gap: 20px;
    .left-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 2px dashed #564ece;
      border-radius: 10px;
      padding: 20px;
      width: 30%;
      max-height: 250px;
      button {
        transition: all 0.5s ease;
        border: 1px solid transparent;
        background: #564ece;
        border-radius: 5px;
        color: white;
        padding: 10px 20px;
        margin-bottom: 20px;

        &:hover {
          color: #564ece;
          border-color: #564ece;
          background: transparent;
        }
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .right-section {
      border: 2px dashed #564ece;
      border-radius: 10px;
      padding: 20px;
      width: 70%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .progress-image-wrapper {
        display: flex;
        gap: 30px;
        .progress-bar-wrapper {
          display: flex;
          flex-direction: column;
          width: 70%;

          .progress-bar {
            background-color: #564ece;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
        .image-wrapper {
          border: 2px solid #564ece;
          padding: 2px;
          width: 200px;
          height: 200px;
          margin: auto;
          border-radius: 50%;
          overflow: hidden;

          &:hover {
            transition: all 0.5s ease;
            border-radius: 20px;

            img {
              border-radius: 20px;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        width: 100%;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
}
