.App {
  text-align: center;
  overflow-x: hidden;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.bg-white {
  background: white;
}
.border-blue {
  border-color: #564ece;
}
.blue {
  color: #564ece;
}
.red {
  color: red;
}
.white {
  color: white;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}

.content-wrapper {
  max-width: 1440px;
  margin: auto;
  padding-top: 50px;
}

.loader-wrapper {
  position: absolute;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
