.myimages-wrapper {
  width: 100%;

  .scroll-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 5px;
    background: #564ece;
  }

  .main-content {
    width: 100%;
    padding: 30px;
    .images-container {
      gap: 50px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @media screen and (max-width: 767px) {
        justify-content: space-evenly;
      }
    }

    .image-card {
      cursor: pointer;
      width: 400px;
      height: 400px;
      overflow: hidden;
      position: relative;
      border-radius: 10px;
      border: 2px solid transparent;

      @media screen and (max-width: 767px) {
        width: 280px;
        height: 280px;
      }
      img {
        transition: all 0.5s ease;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .overlay {
        z-index: 2;
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        backdrop-filter: blur(5px);

        @media screen and (max-width: 991px) {
          display: block;
          backdrop-filter: none;
        }

        .delete-icon {
          position: absolute;
          right: 0;
          padding: 2px 5px;
        }

        .details {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 55px;
          background: #564ece;
          color: white;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 15px;
          @media screen and (max-width: 767px) {
            height: 40px;
          }
          div {
            text-align: left;
          }
        }
      }

      &:hover {
        border-color: #564ece;
        img {
          transform: scale(1.05);
        }
        .overlay {
          display: block;
        }
      }
    }
  }
}

.modal-backdrop {
  opacity: 0.9 !important;
}
.view-image-modal {
  .modal-dialog {
    max-width: 800px !important;
    .modal-content {
      width: max-content;
      height: max-content;
      background: transparent;
      margin-left: auto;
      margin-right: auto;

      .modal-body {
        padding: 0;
        width: 800px;
        height: 800px;

        @media screen and (max-width: 800px) {
          width: 400px;
          height: 400px;
        }
        @media screen and (max-width: 400px) {
          width: 300px;
          height: 300px;
        }
        img {
          border-radius: 20px;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.delete-image-modal {
  button {
    border: 2px solid transparent;
    border-radius: 5px;
    padding: 5px 20px;
  }
  .delete-button {
    border-color: red;
    color: red;
  }
  .cancel-button {
    border-color: #564ece;
    color: #564ece;
  }
}
