.navbar-wrapper {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  color: white;
  background-color: #564ece;

  .active {
    font-weight: 800 !important;
  }

  .title {
    font-size: 30px;
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .list {
      display: flex;
      gap: 30px;
      div {
        cursor: pointer;
        font-weight: 600;
        &:hover {
          text-decoration: underline;
        }
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .drawer-icon {
      display: none;
      position: relative;

      .menu-dropdown {
        display: none;
        position: absolute;
        z-index: 3;
        right: 0;
        border-radius: 10px;
        background: white;
        border: 2px solid #564ece;
        min-width: 150px;
        padding: 10px 20px;
        div {
          color: #564ece;
          font-weight: 600;
        }
        hr {
          color: #564ece;
          opacity: 0.5;
          margin: 0.5rem 0;
        }
      }

      &:hover {
        .menu-dropdown {
          display: block;
        }
      }
      &:not(:hover) {
        .menu-dropdown {
          display: none;
        }
      }

      @media screen and (max-width: 767px) {
        display: block;
      }
    }
  }
}
